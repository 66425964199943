const Shimmer = () => {
    return (
        <div className="flex items-center justify-center w-screen h-screen bg-gray-900">
            <svg className="w-56 -translate-y-12 md:w-56 md:translate-y-0 logo-path" x="px" y="0px" width="100%" viewBox="0 0 150 150" enableBackground="new 0 0 150 150" xmlns="http://www.w3.org/2000/svg">
                <path fill="#F7F7F7" opacity="1.000000" stroke="none" d="M 40.294 100.296 C 35.174 110.556 30.299 120.346 25.064 130.86 C 50.611 130.86 58.76 130.86 84.137 130.86 C 84.137 135.192 84.137 139.001 84.137 143.156 C 51.73 143.156 36.044 143.156 2.612 143.156 C 3.687 140.814 4.534 138.812 5.508 136.869 C 26.587 94.826 47.661 52.78 68.828 10.779 C 69.904 8.647 68.819 10.781 73.178 1.983 C 77.881 9.6 76.798 8.592 77.861 10.704 C 87.52 29.892 97.55 50.021 107.092 69.237 C 91.844 69.27 107.09 69.216 91.816 69.264 C 91.809 69.264 91.765 69.293 73.352 34.605 C 62.063 57.044 51.301 78.436 40.294 100.296 Z"/>
                <path fill="#F8F8F8" opacity="1.000000" d="M 125.298 113.706 C 127.019 116.549 141.165 142.921 141.162 142.921 C 124.812 142.758 124.849 142.876 124.801 142.784 C 116.025 125.968 124.782 142.78 109.677 113.708 C 109.674 113.702 109.437 113.617 103.007 113.55 C 83.712 113.349 90.53 113.378 83.759 113.378 C 83.759 110.217 83.759 106.862 83.759 102.906 C 95.412 102.906 107.196 102.497 118.93 103.054 C 126.324 103.406 127.159 98.156 127.125 92.31 C 127.093 86.656 126.132 84.031 119.176 84.208 C 99.162 84.719 79.126 84.389 58.625 84.389 C 58.625 80.523 58.625 77.161 58.625 73.387 C 82.458 73.387 106.071 73.325 129.682 73.439 C 134.602 73.462 137.23 76.6 137.41 81.07 C 137.709 88.504 137.805 97.918 137.458 105.346 C 137.196 110.963 131.701 113.95 125.298 113.706 Z"/>
            </svg>
        </div>
    );
};

export default Shimmer;
